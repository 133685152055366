.page-item.active span {
  background-color: var(--bs-blue) !important;
  color: #fff;
}

tr {
  cursor: pointer;
}

/* Reduz o tamanho da fonte para todos os elementos */
body {
  font-size: 14px; /* Altere o valor conforme necessário */
}

label{
  font-weight: bold;
}

input, input::placeholder {
  font-size: 16px;
}

/* Seletor para elementos específicos que você deseja reduzir ainda mais */
h1, h2, h3, h4, h5, h6 {
  font-size: 1.5em; /* Exemplo: 1.5 vezes o tamanho da fonte padrão */
}

/* Se necessário, você pode especificar tamanhos de fonte para classes ou IDs específicos */
.classe-exemplo {
  font-size: 0.9em; /* Exemplo de redução de tamanho de fonte para uma classe específica */
}